<template>
  <div class="mt-2">
    <b-row >
      <b-col cols="12" lg="4" >
        <MatchCreator v-on:matchCreated="createMatch"/>
      </b-col>
        <b-col cols="12" lg="8">
          <MatchListView :user="user" v-on:matchSelected="matchSelected"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import MatchCreator from './MatchCreator'
import Match from '../models/Match'
import MatchListView from './MatchListView'
import MatchList from '../models/MatchList'
import Golfer from '../models/Golfer'
import PlayerEditModal from './PlayerEditModal'
import CurrentUser from '../models/CurrentUser'
export default {
  name: 'MatchListPage',
  components: {
    MatchCreator,MatchListView
  },
  props: ['currentMatch'],
  data() {
    return {
      matches: undefined,
      match: undefined,
      matchId: undefined,
      reloadCounter: 0,
      matchesLoading: true,
      activePlayer: undefined,
      user: undefined,
      minimiseCreator: false
    }
  },
  async mounted () {
    this.loadMatches()
    this.loadUser()
  },
  watch: {
  },
  methods: {
    async loadUser() {
      this.user = new CurrentUser()
      await this.user.load()
    },
    playerUpdated(updatedPlayer) {
      this.match.players.filter(player => player.key === updatedPlayer.key)[0] = updatedPlayer
      this.$bvModal.hide('player-edit-modal')
      this.reloadCounter++
    },
    createMatch(match) {
      this.match = match
      this.match.save()
      this.matchId = this.match.uuid
      this.matchSelected(this.match)
    },
    async loadMatches() {
      this.matchesLoading = true
      var matches = new MatchList()
      await matches.load()
      this.matches = matches
      this.matchesLoading = false
    },
    matchSelected(match) {
      this.$emit('matchSelected', match)
    }
  }
}

// When the user scrolls down 50px from the top of the document, resize the header's font size

</script>

<style>
.align-left {
    text-align:left;
    color: #787878;

}

.match-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

img {
  display: inline;
  margin: 0 auto;
  height: 50px;
  width: 50px;
}

#match-creator {
  padding: 50px 10px; /* Some padding */
  color: black;
  text-align: center; /* Centered text */
  top: 0;
  width: 100%; /* Full width */
  transition: 0.2s; /* Add a transition effect (when scrolling - and font size is decreased) */
}

</style>
