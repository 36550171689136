import * as CoursesClient from '../clients/CoursesClient'
export default class CourseList extends  Array{
  constructor() {
    super()
    this.load = load
    this.locations = locations
    this.courseNames = courseNames
    this.getCourse = getCourse
  }
}

async function load() {
  var courses = await CoursesClient.getCourses()
  courses.forEach(course => this.push(course))
}

function locations() {
  var locationsToReturn = []
  this.forEach(course => {
    if (locationsToReturn.indexOf(course.location) === -1) locationsToReturn.push(course.location)
  })
  return locationsToReturn
}

function courseNames(location) {
  var courseNamesToReturn = []
  this.forEach(course => {
    if (courseNamesToReturn.indexOf(course.courseName) === -1 && (!location || location === course.location)) courseNamesToReturn.push(course.courseName)
  })
  return courseNamesToReturn
}

function getCourse(location, courseName) {
  return this.filter(course => course.location === location && course.courseName === courseName)[0]
}
