import {v4 as uuidv4} from 'uuid'
const minAveragePar = 0
const include9HoleRounds = true
const holesForHandicap = 3 * 18
export default class Golfer {
    constructor(player, isSignedInUser) {
      this.id = player.id
      this.firstName = player.firstName
      this.surname = player.surname
      this.key = player.key || uuidv4()
      this.handicap = getLocalHandicap() || 0,
      this.isSignedInUser = isSignedInUser,
      this.fullName = this.firstName + " " + this.surname
    }
    calculateHandicap(matches) {
        var completedMatches = matches.sort((a,b) => new Date(a.date) < new Date(b.date)? 1: -1)
        var scoresToPar = []
        completedMatches.forEach(match => {
          match.course.scoreCard = match.course.scoreCard.filter(hole => hole.hole !== 'Total')
          if (shouldUseMatch(match)) {
            var thisPlayer = match.players.filter(player => player.isSignedInUser)[0] || match.players[0] //Some old rounds don't mark the signed in player - should fix this
            var scoreToPar = totalScoreToParWithNoBlobs(thisPlayer.scores, match.course.scoreCard)
            scoresToPar.push({holes: match.course.scoreCard.length, scoreToPar: scoreToPar, date: match.date})
          }
        })
        var handicapTimeSeries = []
        for (var i = 0; i < scoresToPar.length; i++) {
          var obj = []
          var totalScoreToPar = scoresToPar[i].scoreToPar
          var totalHoles  = scoresToPar[i].holes
          var secondIndex = i + 1
          while (totalHoles < holesForHandicap) {
            if (scoresToPar.length <= secondIndex) break;
            /*
            obj.push({
              scoreToPar: scoresToPar[secondIndex].scoreToPar,
              holes: scoresToPar[secondIndex].holes
            })
            */
            totalScoreToPar += scoresToPar[secondIndex].scoreToPar
            totalHoles += scoresToPar[secondIndex].holes
            secondIndex++
          }
          if (totalHoles >= holesForHandicap) {
            handicapTimeSeries.push({
              holes: totalHoles,
              scoreToPar: totalScoreToPar,
              handicap: (totalScoreToPar / totalHoles * 18),
              date: scoresToPar[i].date,
              obj: obj
            })
          }
        }
        var handicap = handicapTimeSeries[0].scoreToPar / handicapTimeSeries[0].holes * 18
        this.handicapTimeSeries = handicapTimeSeries
        return handicap.toFixed(0)
    }
    save() {
      if (this.isSignedInUser) this.setHandicap(this.handicap)
    }
    setHandicap(handicap) {
      this.handicap = handicap
      if (this.isSignedInUser) {
        localStorage.handicap = handicap
      }
    }
    getTeam(match) {
      return match.teams.teamA.players.filter(key => key === this.key).length === 1? 'teamA': 'teamB'
    }
}

function getLocalHandicap() {
  var handicap = localStorage.handicap
  if (!handicap) handicap = 0
  localStorage.handicap = handicap
  return handicap
}

function totalScoreToParWithNoBlobs(scores, holes) {
  if (!scores) console.log(holes[0])
  var totalScore = scores.reduce((total, score) => {
    var par = holes.filter(hole => hole.hole === score.hole)[0].par
    var thisScore = score.shots
    if (thisScore - par > 2) {
      thisScore = par + 2
    }
    return total + thisScore
  }, 0)
  var par = holes.reduce((total, hole) => total + hole.par, 0)
  return totalScore - par
}

function shouldUseMatch(match) {
  var averagePar = match.course.scoreCard.reduce((total, hole) => total += hole.par, 0) / match.course.scoreCard.length
  if (averagePar <= minAveragePar) return false
  if (!include9HoleRounds && match.course.scoreCard.length === 9) return false
  return match.players[0].scores.filter(score => !score.shots).length === 0 //remove incomplete rounds
}
