<template>
  <div>
    <VueApexChart v-if="series" height="200" :options="options" :series="series"></VueApexChart>
  </div>
</template>
<script>

import VueApexChart from 'vue-apexcharts'
import ShotStats from '../../models/ShotStats'
import moment from 'moment'
export default {
  name: 'GreensInRegulationTimeSeriesChart',
  components: {
    VueApexChart
  },
  props: ['matches', 'player'],
  data() {
    return {
      data: undefined,
      series: undefined,
      options: undefined
    }
  },
  mounted () {
    this.data = this.createData()
  },
  methods: {
    createData() {
      var shotStats = new ShotStats(this.matches, this.player)
      var putts = shotStats.putts
      this.series = [{
        name: "putts",
        data: putts.series.map(obj => obj.average)
      }]

      this.options = {

        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 9,
            left:4,
            blur: 10,
            opacity: 0.2
          },

          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: ['4']
        },
        colors: ['#f54242'],
        xaxis: {
          categories: putts.dates,
          labels: {
            rotate: 0,
            style: {
              fontSize: '10px'
            }
          }
        },
        yaxis: {
          min: 0, max: 3
        }
      }
      //var labels = timeSeries.map(obj => obj.date)
      /*
      var labels = timeSeries.dates
      var data = {
        labels: labels,
        datasets: []
      }
      data.datasets.push({
        backgroundColor: '#f87979',
        data:timeSeries.series.map(obj => obj.percentage)
      })
      return data
      */
    }

  }
}

function getDateList(data) {
  return data.map(obj => {
    return moment(obj.date).format("YYYY-MM-DD")
  })
}

</script>
