<template>
  <div >
    <b-container>

      <b-row no-gutters align-h="center">
        <b-col v-for="(distance, id) in distances" v-bind:key="id" cols="2" >
          <b-button class="selector-button"  squared size="lg" @click="selected(distance)">{{distance}}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {distances} from '../models/StandardData'
export default {
  name: 'DistanceSelector',
  props: {
    msg: String
  },
  data () {
    return {
      distances: distances
    }
  },
  methods: {
    selected(distance) {
      this.$emit("distanceSelected", distance)
    }
    /*
    createClubGroups (cols) {
      this.clubGroups = []
      for (var i = 0; i < cols.length; i++) {
        var group = []
        group.push(clubs[i])
      }
    }
    */
  },
  mounted () {
    //createClubGroups()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .selector-button {
    height: 90px
  }
</style>
