import axios from 'axios'
import Vue from 'vue'
import { LoaderPlugin } from 'vue-google-login';

Vue.use(LoaderPlugin, {
  client_id: "726629554189-9q49g6o2lqd2mtvgldmprqofnune34o6.apps.googleusercontent.com"
});
var baseUrl = "https://fceul9wqvk.execute-api.us-east-1.amazonaws.com/prod/matches"
//var url = "https://fceul9wqvk.execute-api.us-east-1.amazonaws.com/prod/matches2"
export async function saveMatch(match) {
  match.synced = undefined
  var token = await getSignedInUserToken()

  var result  = await axios.post(baseUrl, match, {headers: {'user-token' : token}})
  return result
}

export async function getMatches(location, courseName) {
  var url = baseUrl
  if (location) {
    url += "?location=" + location
    if (courseName) {
      url+= "&courseName=" + courseName
    }
  }
  var token = await getSignedInUserToken()
  var result  = await axios.get(url, {headers: {'user-token' : token}})
  return result.data
}

export async function deleteMatch(id) {
  var token = await getSignedInUserToken()
  var result  = await axios.delete(baseUrl, {headers: {'user-token' : token}, data: {id: id}})
  return result.data
}

async function getSignedInUserToken() {
  var auth2 = await Vue.GoogleAuth
  var user = auth2.currentUser.get()
  return user.getAuthResponse().id_token
}
