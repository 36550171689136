import {v4 as uuidv4} from 'uuid'
import * as MatchesClient from '../clients/MatchesClient'
import moment from 'moment'
export default class Match {
    constructor(players, course) {
      this.uuid = uuidv4()
      if (course) {
        this.scoreCard = course.scoreCard
      }else {
        this.course = {}
      }
      if (players) {
        this.players = players
      }else {
        this.players = []
      }
      this.players.forEach(player => {
          player.scores = []
          this.course.scoreCard.forEach(hole => {
            player.scores.push({hole: hole.hole})
          })
      })
      this.synced = false
      this.date = moment().format("YYYY-MM-DD")
    }
    async save(localOnly, overrideSynced) {
      //TODO - check for unsynced localstorage version of the match before overwriting (am doing this already in MatchList but if that fails, this will overwrite)
      //Save to local storage, marked as unsynced unless explicity overwritten
      if (!overrideSynced) this.synced = false
      localStorage['match-' + this.uuid] = JSON.stringify(this)

      //Assuming this is to get rid of any 'total' entries in a player's scores
      this.players.forEach(player => {
        if (player.scores) {
          player.scores = player.scores.filter(score => score.hole > 0 && score.hole < 19)
        }
      })
      //Save to remote storage
      if (!localOnly) {
        try {
          await MatchesClient.saveMatch(this)
          this.synced = true
          localStorage['match-' + this.uuid] = JSON.stringify(this)
          //console.log("Match saved to remote storage")
        }catch(err) {
          console.error("Error saving match to remote storage")
        }
      }
    }
    async delete() {
      //console.log("Deleting match " + 'match-' + this.uuid)
      await MatchesClient.deleteMatch(this.id)
      localStorage.removeItem('match-' + this.uuid)
    }
    async load(uuid) {
      if (uuid && uuid.indexOf("match-") > -1) uuid = uuid.split("match-")[1]
      if (uuid) this.uuid = uuid
      var data = localStorage['match-' + this.uuid]
      if (data) {
        data = JSON.parse(localStorage['match-' + this.uuid])
        Object.keys(data).forEach(key => this[key] = data[key])
      }else {
        //loadFromRemote
        this.uuid = undefined
      }

    }
    async addAverages() {
      var matches = await MatchesClient.getMatches(this.course.location, this.course.courseName)
      matches = matches.filter(match => match.id !== this.id)
      this.players.forEach(player => {
        if (!player.scores) {
          player.scores = []
          this.course.scoreCard.forEach(hole => {
            player.scores.push({hole: hole.hole})
          })
        }
        player.scores.forEach(hole => {
          var holesForPlayer = allHolesForPlayer(matches, player.key)
          var previousScoresOnThisHole = holesForPlayer.filter(holeForPlayer => holeForPlayer && holeForPlayer.hole === hole.hole)
          var sum = previousScoresOnThisHole.reduce((total, score) => total + score.shots, 0)
          var average = sum / previousScoresOnThisHole.length
          hole.average = average
        })
      })
    }
    checkScoresExist() {
      this.players.forEach(player => {
        if (!player.scores) {
          player.scores = []
          this.course.scoreCard.forEach(hole => {
            player.scores.push({hole: hole.hole})
          })
        }
      })
    }
    playerByKey(key) {
      return this.players.filter(player => player.key === key)[0]
    }
}

function allHolesForPlayer(matches, playerKey) {
  var holes = []
  matches.forEach(match => {
    if (match.players.filter(player => player.key === playerKey)[0]) {
      holes = holes.concat(match.players.filter(player => player.key === playerKey)[0].scores)
    }
  })
  return holes
}
