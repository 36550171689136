<template>
  <div>
    <VueApexChart  v-if="series" height="200" :options="options" :series="series"></VueApexChart>
  </div>
</template>
<script>

import VueApexChart from 'vue-apexcharts'
import ShotDataByDate from '../models/ShotDataByDate'
export default {
  name: 'MissHitsChart',
  components: {
    VueApexChart
  },
  props: ['data', 'club'],
  data() {
    return {
      options: undefined,
      series: undefined
    }
  },
  mounted () {
    this.createData()
  },
  watch: {
    club () {
      this.createData()
    }
  },
  methods: {
    createData() {
      var shotData = new ShotDataByDate(this.data)
      var dates = Object.keys(shotData).sort((a,b) => new Date(a) - new Date(b))

      this.series = [
        {
          name: "MissHits",
          data: dates.map(date => {
            var summaryData = shotData[date].totals
            if (this.club) summaryData = shotData[date].clubs[this.club]
            return getMissHitsPercent(summaryData)
          })
        }

      ]

      this.options = {
        title: {
          text: "Miss Hits",
          align: "center"
        },
        chart: {
          type: 'bar',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 9,
            left:4,
            blur: 10,
            opacity: 0.2
          },

          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: ['4']
        },
        colors: ['#00d94a'],
        xaxis: {
          categories: dates,
          labels: {
            rotate: -30,
            style: {
              fontSize: '8px'
            }
          }
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: (val => val.toFixed(0))
          }
        }
      }

    }
  }
}

function getMissHitsPercent(obj) {
  if (obj) {
    var missedHits = 0
    if (obj.shotResult.Boffed) missedHits += obj.shotResult.Boffed.count
    if (obj.shotResult.Slice) missedHits += obj.shotResult.Slice.count
    if (obj.shotResult.Hook) missedHits += obj.shotResult.Hook.count

    return (missedHits / obj.count * 100).toFixed(2)
  }else {
    return 0
  }
}
</script>
