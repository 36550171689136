import axios from 'axios'
import Vue from 'vue'
import { LoaderPlugin } from 'vue-google-login';

Vue.use(LoaderPlugin, {
  client_id: "726629554189-9q49g6o2lqd2mtvgldmprqofnune34o6.apps.googleusercontent.com"
});
var url = "https://fceul9wqvk.execute-api.us-east-1.amazonaws.com/prod/courses"

export async function getCourses() {
  var token = await getSignedInUserToken()
  var result  = await axios.get(url, {headers: {'user-token' : token}})
  return result.data
}

export async function saveCourse(course) {
  var token = await getSignedInUserToken()
  var result  = await axios.post(url, course, {headers: {'user-token' : token}})
  return result.data
}

async function getSignedInUserToken() {
  var auth2 = await Vue.GoogleAuth
  var user = auth2.currentUser.get()
  return user.getAuthResponse().id_token
}
