export var clubs = ["D","5W","3H","4I","5I","6I","7I","8I","9I","PW","SW"]
export var directions = ["VL","L","Straight","R","VR"]
export var distances = createDistances(60,290)
export var shotResults = ["Straight", "Fade", "Draw","Slice", "Hook", "Under", "Over","Boffed"]

function createDistances(min, max) {
  var arr = []
  for (var i = min; i <= max; i += 10) {
    arr.push(i)
  }
  return arr
}
