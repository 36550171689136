<template>
  <div id="app">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
      <b-navbar class="center-nav nav" type="dark" variant="dark" v-if="signedIn" fixed="top">
          <b-navbar-nav fill align="center" class="nav-max-width"> <!--class=""nav-fill-->
            <b-nav-item v-if="false" @click="activePage = 'shotPage'">
              <i class="material-icons nav-icon">adjust</i>
              <p class="nav-title nav-icon-text">Record Shots</p>
            </b-nav-item>
            <b-nav-item @click="activePage = 'summary'" :active="activePage==='summary'">
              <i class="material-icons nav-icon">assessment</i>
              <p class="nav-title nav-icon-text">Stats</p>
            </b-nav-item>
            <b-nav-item @click="currentMatch = false; activePage = 'match'" :active="activePage==='match'">
              <i class="material-icons nav-icon">emoji_flags</i>
              <p class="nav-title nav-icon-text">Matches</p>
            </b-nav-item>
            <b-nav-item @click="activateScorecard()" :active="activePage==='scorecard'" >
              <i class="material-icons nav-icon">table_chart</i>
              <p class="nav-title nav-icon-text">ScoreCard</p>
            </b-nav-item>
          </b-navbar-nav>
      </b-navbar>

    <b-container class="mt-6">
      <div v-if="signedIn">
        <b-row>
          <b-col sm="12">
            <CourseEditor v-if="activePage === 'courseEditor'"/>
            <ShotPage v-if="activePage === 'shotPage'" :matchContext="matchContext"/>
            <ShotSummaryPage v-if="activePage === 'summary'"/>
            <MatchListPage v-if="activePage === 'match'" @matchSelected="matchSelected" v-on:navigateToShots="matchContext = true; activePage = 'shotPage'" :currentMatch="currentMatch"/>
            <ScoreCardPage v-if="activePage === 'scorecard'" :matchId="matchId" />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-row align-h="center" align-v="center" >
      <b-col>
      <b-row align-h="center" align-v="end" v-if="!signedIn" style="min-height: 300px"  >
        <b-col sm="auto">
          <b-badge pill variant="warning">beta</b-badge>

            <p class="title-text">GolfScorer</p>
            <p> Golf is hard. <br> Keeping score doesn't have to be.</p>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center" >
        <b-col cols="auto">
          <GoogleSignIn  v-on:signOut="signOutEvent" v-on:signIn="signInEvent"/>
        </b-col>
        <b-col cols="auto" v-if="signedIn">
          <b-button variant="link" @click="activePage = 'courseEditor'">CourseEditor</b-button>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center" v-if="signedIn" >
        <b-col cols="auto">
          <p style="font-size:5pt">v0.5.3</p>
        </b-col>
      </b-row>
    </b-col>
    </b-row>

  </div>
</template>

<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import ShotPage from './components/ShotPage'
import GoogleSignIn from './components/GoogleSignIn'
import MatchListPage from './components/MatchListPage'
import ScoreCardPage from './components/ScoreCardPage'
import ShotSummaryPage from './components/ShotSummaryPage'
import CourseEditor from './components/CourseEditor'
import * as ShotClient from './clients/ShotClient'
import * as LoginClient from './clients/LoginClient'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vue-select/dist/vue-select.css';
Vue.use(BootstrapVue)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCm3Iu0yACGjNeKdV7Fmbf6ejaPim-PsTg',
    libraries: 'places'
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})
export default {
  name: 'App',
  components: {
    ShotPage, ShotSummaryPage,MatchListPage,GoogleSignIn, CourseEditor, ScoreCardPage
  },
  data() {
    return {
      activePage: 'match',
      signedIn: false,
      auth2: undefined,
      matchContext: false,
      currentMatch: false,
      matchId: undefined
    }
  },
  methods: {
    signOutEvent() {
      this.signedIn = false
    },
    async signInEvent() {
      var user = await LoginClient.login()
      this.signedIn = true
    },
    matchSelected(match) {
      this.matchId = match.uuid
      this.activePage = "scorecard"
    },
    activateScorecard() {
      if (this.matchId)  {
        this.currentMatch = true
        this.activePage = 'scorecard'
      }
    }
  },
  mounted() {
    //ShotClient.migrateShots()
  },
  watch: {
  },
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fafafa;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

h3 {
  color: rgb(5, 15, 25);
  font-size: 12pt;
  text-align: left
}

.card-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px
}

.selector-button {
  width:100%;
  height: 100px;
  font-size: 16px;
  padding: 0px
}

.clickable {
  cursor: pointer;
}

.title-text {
  font-size: 60px;
  font-family: Helvetica, sans-serif;
  font-weight: 200
}

.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.header-link, .header-link:visited {
  color:#2e2e2e;
}

.header-link:hover {
  color: #9e9e9e;
  text-decoration: none;
}

.header-link:focus{
  text-decoration: none;
  font-weight: bold
}

.navbar {
  padding:3px
}

.center-nav {
  justify-content: center
}

.nav-title {
  font-size: 10px;
  margin: 0px
}
.nav-fill {
  width:100%;
  margin-left: 20px;
  margin-right: 20px
}

.nav-max-width {
  max-width: 500px
}

.mt-6 {
  margin-top: 5rem !important
}

.card-bottom-border {
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left:20px;
  margin-right:20px;

}

.button-primary {
  background-color: #6dede1;
  border: 0px;
  width: 100%;
  font-weight: 700
}

.nav-icon, .nav-icon-text {

}

.form-label {
  color: grey;
  font-weight: 100;
  font-size: 10pt
}

.date-picker, .date-picker > button, .date-picker > label {
  border: 0px;
  width: 100%;
  font-weight: 100;
}
</style>
