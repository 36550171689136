<template>
  <b-modal hide-header hide-footer centered id="team-edit-modal" >
    <b-form>
      <label class="form-label" for="handicap-entry">TEAM A</label>
      <p class="form-label">{{this.match.players[0].firstName + ' ' + this.match.players[0].surname}}</p>
      <VueSelect placeholder="Select player" class="mt-2" :options="getPlayerDropdownList()" v-model="selectedPlayerA2"/>
      <label class="form-label mt-3" for="handicap-entry">TEAM B</label>
      <VueSelect placeholder="Select player" class="" :options="getPlayerDropdownList()" v-model="selectedPlayerB1" />
      <VueSelect placeholder="Select player" class="mt-2" :options="getPlayerDropdownList()" v-model="selectedPlayerB2" />
      <b-button class="mt-2 button-primary" @click="submitTeams">Update</b-button>
      <p v-if="error" class="error-text mt-2">{{errorText}}</p>
    </b-form>
  </b-modal>
</template>

<script>
import VueSelect from 'vue-select'
export default {
  name: 'TeamEditModal',
  components: {
    VueSelect
  },
  props: ['match'],
  data() {
    return {
      selectedPlayerA2: undefined,
      selectedPlayerB1: undefined,
      selectedPlayerB2: undefined,
      error: true,
      errorText: undefined
    }
  },
  mounted () {
    if (this.match && this.match.teams) {
      this.selectedPlayerA2 = this.match.playerByKey(this.match.teams.teamA.players[1])
      this.selectedPlayerB1 = this.match.playerByKey(this.match.teams.teamB.players[0])
      this.selectedPlayerB2 = this.match.playerByKey(this.match.teams.teamB.players[1])
    }
  },
  watch: {

  },
  methods: {
    getPlayerDropdownList(isFirst) {
      return this.match.players.slice(1,this.match.players.length).map(a => {
        a.label = a.firstName + ' ' + a.surname
        return a
      })
    },
    submitTeams() {
      this.error = false
      //Not all players selected
      var selectedPlayerCount = 0
      if (this.selectedPlayerA2) selectedPlayerCount++
      if (this.selectedPlayerB1) selectedPlayerCount++
      if (this.selectedPlayerB2) selectedPlayerCount++
      if (selectedPlayerCount + 1 !== this.match.players.length) {
        this.error = true
        this.errorText = "Not all players selected"
      }

      //Same player selected twice
      if (this.selectedPlayerA2.key === this.selectedPlayerB1.key || this.selectedPlayerA2.key === this.selectedPlayerB2.key || this.selectedPlayerB2.key === this.selectedPlayerB1.key) {
        this.error = true
        this.errorText = "Same player selected twice"
      }


      if (!this.error) {
        this.match.teams = {
          teamA: {
            players:[this.match.players[0].key, this.selectedPlayerA2.key]
          },
          teamB: {
            players: [this.selectedPlayerB1.key, this.selectedPlayerB2.key]
          }
        }

        var newPlayerOrder = [
          this.match.players[0],
          this.match.playerByKey(this.selectedPlayerA2.key),
          this.match.playerByKey(this.selectedPlayerB1.key),
          this.match.playerByKey(this.selectedPlayerB2.key)
        ]
        this.match.players = newPlayerOrder
        console.log(newPlayerOrder)
        console.log(this.match.players)
        this.$emit("teamsUpdated")
      }
    }
  }
}
</script>

<style>

.error-text {
  color: red;
  font-size: 10pt
}
</style>
