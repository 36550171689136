<template>
  <div v-if="match">
    <PlayerEditModal :player="newPlayer" v-on:playerUpdated="playerUpdated" v-on:playerAdded="addPlayer"/>
    <b-card class="mb-3" >
      <div class="card-title">
        <h3>New Round</h3>
      </div>
    <b-form  class="mt-2">
      <b-row align-h="center">
        <b-col cols="auto">
          <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2 date-picker" v-model="match.date" />
      </b-col>
      </b-row>
      <VueSelect placeholder="Select location" class="mt-2" v-if="coursesLoaded" :options="courses.locations()" v-model="selectedLocation" @input="locationSelected" />
      <VueSelect placeholder="Select course" class="mt-2" v-if="courseNames && selectedCourseName !== selectedLocation" :options="courseNames" v-model="selectedCourseName" />
    </b-form>

    <p class="align-left mt-4 form-label underline">PLAYERS</p>
    <b-card no-body v-for="player in players" v-bind:key="player.key" class="mb-2 player-card" @click="showEditPlayerModal(player)" >
      <b-row  align-v="center">
        <b-col cols="10">
          <b-row class="ml-1" align-h="start">
            <b-col cols="auto">
              {{player.firstName + ' ' + player.surname}} <span class="small-italic ml-3">Handicap: {{player.handicap}}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2">
          <i class="material-icons md-24 clickable align-middle" @click.stop="removePlayer(player)">delete</i>
        </b-col>
      </b-row>
     </b-card>
     <b-button @click="showAddPlayerModal" variant="light" >Add Player</b-button>
   <div>
     <b-button :disabled="!selectedCourseName" class="mb-2 mt-2" style="width:100%" @click="createMatch">Create Match</b-button>
   </div>
 </b-card>
</div>
</template>
<script>
import Match from '../models/Match'
import Golfer from '../models/Golfer'
import CourseList from '../models/CourseList'
import CurrentUser from '../models/CurrentUser'
import VueSelect from 'vue-select'
import PlayerEditModal from './PlayerEditModal'

export default {
  components: {
    VueSelect, PlayerEditModal
  },
  props: [],
  data() {
    return {
      match: undefined,
      players: [],
      courses: undefined,
      coursesLoaded: false,
      courseNames: undefined,
      selectedCourseName: undefined,
      selectedLocation: undefined,
      newPlayer: {},
    }
  },
  async mounted () {
    this.loadCourses()
    this.match = new Match()
    var currentUser = new CurrentUser()
    await currentUser.load()
    var me = new Golfer(currentUser, true)
    this.players.push(me)
  },
  watch : {
    selectedCourseName() {
      this.courseSelected(this.selectedCourseName)
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show('player-edit-modal')
    },
    createMatch() {
      this.match.players = this.players
      if (this.selectedCourseName) {
        this.$emit('matchCreated', this.match)
      }
    },
    locationSelected(location) {
      if (location) {
        if (location !== this.match.course.location) {
          this.selectedCourseName = undefined
        }
        this.selectedLocation = location
        this.courseNames = this.courses.courseNames(location)
        if (this.courseNames.length === 1) {
          this.courseNames[0]
          this.courseSelected(this.courseNames[0])
        }
      }else {
        this.courseNames = undefined
        this.selectedLocation = undefined
      }
    },
    courseSelected(courseName) {
      this.selectedCourseName = courseName
      this.match.course = this.courses.getCourse(this.selectedLocation, courseName)
      this.scoreCard = this.match.course.scoreCard
    },
    async loadCourses() {
      this.coursesLoaded = false
      this.courses = new CourseList()
      await this.courses.load()
      this.coursesLoaded = true
    },
    removePlayer(player) {
      this.players.splice(this.players.indexOf(player),1)
    },
    addPlayer() {
      var newPlayer = new Golfer(this.newPlayer)
      newPlayer.handicap = this.newPlayer.handicap
      this.players.push(newPlayer)
      this.$bvModal.hide('player-edit-modal')
      this.newPlayer = {}
    },
    playerUpdated() {
      this.$bvModal.hide('player-edit-modal')
      this.newPlayer.save()
      this.newPlayer = new Object()
    },
    showEditPlayerModal(player) {
      this.newPlayer = player
      this.$bvModal.show('player-edit-modal')
    },
    showAddPlayerModal() {
      this.newPlayer = new Object()
      this.showModal()
    }
  }
}

</script>

<style >
.player-card {
  padding: 5px;

}

.small-italic {
  font-size: 12px;
  color: #787878;

}

.align-middle {
  vertical-align: middle;
}

.form-control-with-dropdown {
  width: 85%
}

.vs__search {
  color: grey
}

.form-label {
  color: grey;
  font-weight: 100;
  font-size: 10pt
}
</style>
