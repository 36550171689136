<template>
  <div >
    <b-container>

      <b-row no-gutters align-h="center">
        <b-col v-for="(direction, id) in directions" v-bind:key="id" :cols="2" >
          <b-button class="selector-button"  squared size="lg" @click="selected(direction)">
            <div v-if="direction === 'VL' || direction === 'VR'">{{direction.substr(0,2)}}</div>
            <i v-if="direction === 'Straight'" class="material-icons">arrow_upward</i>
            <i v-if="direction === 'L'" class="material-icons">arrow_back</i>
            <i v-if="direction === 'R'" class="material-icons">arrow_forward</i>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {directions} from '../models/StandardData'
export default {
  name: 'DirectionSelector',
  props: {
    msg: String
  },
  data () {
    return {
      directions: directions
    }
  },
  methods: {
    selected(direction) {
      this.$emit("directionSelected", direction)
    }
    /*
    createClubGroups (cols) {
      this.clubGroups = []
      for (var i = 0; i < cols.length; i++) {
        var group = []
        group.push(clubs[i])
      }
    }
    */
  },
  mounted () {
    //createClubGroups()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
