import moment from 'moment'
export default class PuttsStats {
  constructor(playerScores) {
    this.playerScores = playerScores
    var puttsMap = this.playerScores.reduce((dateObj, score) => {
      if (!dateObj[score.date]) dateObj[score.date] = { putts: 0, count: 0 }
      dateObj[score.date].count++
      dateObj[score.date].putts += score.putts
      return dateObj
    }, {})

    var puttsList = Object.keys(puttsMap).map(dateKey => {
      puttsMap[dateKey].date = dateKey
      puttsMap[dateKey].average = (puttsMap[dateKey].putts / puttsMap[dateKey].count).toFixed(2) * 1
      return puttsMap[dateKey]
    })
    this.series = puttsList.sort((a,b) => new Date(a.date) > new Date(b.date)? 1: -1)
    this.dates = this.series.map(obj => moment(obj.date).format("DD-MMM-YY"))
  }
  last(amount) {
      var arr = this.playerScores.slice(0,amount)
      var girs = arr.reduce((total, val) => {
        return total + (isGIR(val)? 1:0)
      }, 0)
      return (girs / amount * 100).toFixed(0) * 1
  }
}


function isGIR(score) {
  return (score.par - 2) >= (score.shots - score.putts)
}
