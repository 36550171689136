<template>
  <div >
    <b-table class="distances-table" responsive small :items="items" :fields="fields"/>
    <b-form >
      <b-form-checkbox switch v-model="showDetail">Detailed</b-form-checkbox>
      <p v-if="showDetail" class="ml-4" style="margin:0px; font-size: 12px; font-weight: bold"> m = "Match p = "Practice"</p>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'DistancesTable',
  components: {

  },
  props: ['shots'],
  data () {
    return {
      items: undefined,
      allFields: [
        {key: 'club', label: 'Club', class: 'bold'},
        {key: 'average', label: 'Ave', variant: 'success', class: 'bold'},
        {key: 'averageMatch', label: '(m)', variant: 'success'},
        {key: 'averagePractice', label: '(p)', variant: 'success'},
        {key: 'cleanHits', label: 'Clean', variant: 'info', class: 'bold'},
        {key: 'cleanHitsMatch', label: '(m)', variant: 'info'},
        {key: 'cleanHitsPractice', label: '(p)', variant: 'info'},
        {key: 'missHits', label: 'Miss', variant: 'warning', class: 'bold'},
        {key: 'missHitsMatch', label: '(m)', variant: 'warning'},
        {key: 'missHitsPractice', label: '(p)', variant: 'warning'},
      ],
      showDetail: true,
      fields: undefined
    }
  },
  watch: {
    showDetail() {
      this.createItems()
    }
  },
  methods: {
    createItems() {
      if (!this.showDetail) {
        this.fields = this.allFields.filter(field => field.key.indexOf("Practice") === -1 && field.key.indexOf("Match") === -1)
      }else {
        this.fields = this.allFields
      }
      var clubsObj = {}
      this.shots.forEach(shot => {
        if (!clubsObj[shot.club]) {
          clubsObj[shot.club] = {
            count: {
              practice: 0,
              match: 0
            },
            distanceTotal: {
              practice: 0,
              match: 0
            },
            cleanHits: {
              practice: 0,
              match: 0
            },
            missHits: {
              practice: 0,
              match: 0
            },
            distance: {"VL" :0, "L": 0, "Straight": 0, "R" : 0, "VR" : 0}
          }
        }

        var type = 'match'
        if (!shot.match) type = 'practice'
        clubsObj[shot.club].count[type]++
        if (shot.shotResult === "Fade" || shot.shotResult === "Draw" || shot.shotResult === "Straight") {
          clubsObj[shot.club].distanceTotal[type] += shot.distance * 1
          clubsObj[shot.club].cleanHits[type]++
          clubsObj[shot.club].distance[shot.direction] ++
        }else {
          if (shot.shotResult === "Boffed" || shot.shotResult === "Hook" || shot.shotResult === "Slice")
          clubsObj[shot.club].missHits[type]++
        }
      })
      this.items = []
      Object.keys(clubsObj).forEach(club => {
        var obj = clubsObj[club]
        var item = {
          club: club,
          averageMatch: "-",
          averagePractice: "-",
          average: "-",
          cleanHitsMatch: "-",
          cleanHitsPractice: "-",
          cleanHits: "-",
          missHitsMatch: "-",
          missHitsPractice: "-",
          missHits: "-",
        }

        if (clubsObj[club].distanceTotal.match > 0) {
          item.averageMatch = (clubsObj[club].distanceTotal.match / clubsObj[club].cleanHits.match).toFixed(0)
          item.cleanHitsMatch = (clubsObj[club].cleanHits.match / clubsObj[club].count.match * 100).toFixed(0) + "%"
          item.missHitsMatch = (clubsObj[club].missHits.match / clubsObj[club].count.match * 100).toFixed(0) + "%"
        }

        if (clubsObj[club].distanceTotal.practice > 0) {
          item.averagePractice = (clubsObj[club].distanceTotal.practice / clubsObj[club].cleanHits.practice).toFixed(0)
          item.cleanHitsPractice = (clubsObj[club].cleanHits.practice / clubsObj[club].count.practice * 100).toFixed(0) + "%"
          item.missHitsPractice = (clubsObj[club].missHits.practice / clubsObj[club].count.practice * 100).toFixed(0) + "%"
        }

        if (clubsObj[club].distanceTotal.match > 0 || clubsObj[club].distanceTotal.practice > 0) {
          item.average = ((clubsObj[club].distanceTotal.practice + clubsObj[club].distanceTotal.match) / (clubsObj[club].cleanHits.practice + clubsObj[club].cleanHits.match)).toFixed(0)
          item.cleanHits = ((clubsObj[club].cleanHits.practice + clubsObj[club].cleanHits.match) / (clubsObj[club].count.practice + clubsObj[club].count.match) * 100).toFixed(0) + "%"
          item.missHits = ((clubsObj[club].missHits.practice + clubsObj[club].missHits.match) / (clubsObj[club].count.practice + clubsObj[club].count.match) * 100).toFixed(0) + "%"
        }

        this.items.push(item)
      })
      this.items.sort((a,b) => b.average - a.average)
    }
  },
  async mounted () {
    this.createItems()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .distances-table {
    font-size: 12px
  }

  .bold {
    font-weight: bold;
  }
</style>
