<template>
  <div>
    <b-spinner class="mt-3 mb-3" v-if="!matchesLoaded || !this.shots" variant="success" type="grow" label="Spinning"></b-spinner>
      <b-row no-gutters class="mt-1" v-if="matchesLoaded">
        <b-col sm="12" md="6">
          <b-card class="m-1">
            <h6 v-if="handicapTimeSeries">Handicap ({{handicapTimeSeries[handicapTimeSeries.length -1].handicap.toFixed(0)}})</h6>
            <HandicapTimeSeriesChart v-if="matchesLoaded && handicapTimeSeries" :data="handicapTimeSeries" />
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card class="m-1">
            <h6 v-if="matchesLoaded">Shots To Par</h6>
            <ShotsToParTimeSeriesChart v-if="matchesLoaded" :matches="matches" :player="me"/>
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card class="m-1">
            <h6 v-if="matchesLoaded">Shots To Par</h6>
            <ShotsToParDonutChart v-if="matchesLoaded" :matches="matches" :player="me"/>
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card class="m-1">
            <h6 v-if="matchesLoaded">Greens In Regulation Over Time</h6>
            <GreensInRegulationTimeSeriesChart v-if="matchesLoaded" :matches="matches" :player="me"/>
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card class="m-1">
            <h6 v-if="matchesLoaded">Greens In Regulation (Last 50)</h6>
            <GreensInRegulationPieChart v-if="matchesLoaded" :matches="matches" :player="me"/>
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card class="m-1">
            <h6 v-if="matchesLoaded">Average Putts</h6>
            <PuttsTimeSeriesChart v-if="matchesLoaded" :matches="matches" :player="me"/>
          </b-card>
        </b-col>
      </b-row>
      <b-card class="m-1" v-if="this.shots && false">
        <h6>Club Stats</h6>
        <b-dropdown :text="activeClub || 'All clubs'" class="m-md-2">
          <b-dropdown-item v-for="(club, id) in clubs" v-bind:key="id" @click="setActiveClub(club)">{{club}}</b-dropdown-item>
        </b-dropdown>
       <b-row no-gutters>
         <b-col sm="12" md="6">
           <MissHitsChart class="mt-3" v-if="shots" :data="this.shots" :club="activeClub"/>
         </b-col>
          <b-col sm="12" md="6">
            <DirectionChart class="mt-3" v-if="shots" :data="this.shots" :club="activeClub"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <DistancesTable v-if="shots" :shots="shots" />
          </b-col>
        </b-row>
    </b-card >
  </div>
</template>

<script>
import * as ShotClient from '../clients/ShotClient'
import DistancesTable from './DistancesTable'
import MissHitsChart from './MissHitsChart'
import DirectionChart from './DirectionChart'
import HandicapTimeSeriesChart from './charts/HandicapTimeSeriesChart'
import PuttsTimeSeriesChart from './charts/PuttsTimeSeriesChart'
import GreensInRegulationTimeSeriesChart from './charts/GreensInRegulationTimeSeriesChart'
import ShotsToParDonutChart from './charts/ShotsToParDonutChart'
import ShotsToParTimeSeriesChart from './charts/ShotsToParTimeSeriesChart'
import GreensInRegulationPieChart from './charts/GreensInRegulationPieChart'
import {clubs} from '../models/StandardData'
import MatchList from '../models/MatchList'
import Golfer from '../models/Golfer'
import CurrentUser from '../models/CurrentUser'
import moment from 'moment'
export default {
  name: 'ShotSummaryPage',
  components: {
    DistancesTable,
    MissHitsChart,
    ShotsToParDonutChart,
    DirectionChart, HandicapTimeSeriesChart,GreensInRegulationTimeSeriesChart,GreensInRegulationPieChart, PuttsTimeSeriesChart,ShotsToParTimeSeriesChart
  },
  props: {
    msg: String
  },
  data () {
    return {
      shots: undefined,
      items: undefined,
      activeClub: undefined,
      clubs: ["All clubs"].concat(clubs),
      matchesLoaded: false,
      handicap: undefined,
      handicapTimeSeries: undefined,
      me: undefined,
      matches: undefined,
      series: [70],
      options: {
        chart: {
          type: 'radialBar'
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '50%'
            }
          }
        },
        labels: ['cricket'],
      }
    }
  },
  methods: {
    async loadHandicap() {
      var currentUser = new CurrentUser()
      await currentUser.load()
      this.me = new Golfer(currentUser, true)
      var matchList = new MatchList()
      await matchList.load()
      this.matches = matchList
      this.matchesLoaded = true
      this.handicap = this.me.calculateHandicap(this.matches)
      this.me.handicapTimeSeries = this.me.handicapTimeSeries.sort((a,b) => new Date(a.date) > new Date(b.date)? 1: -1)
      this.handicapTimeSeries = this.me.handicapTimeSeries
    },
    setActiveClub(club) {
      if (club === "All clubs") {
        this.activeClub = undefined
      }else {
        this.activeClub = club
      }
    },
    createItems() {
      this.items = []
      var dates = {}
      this.shots.forEach(shot => {
        if (shot.date.length > 10) {
            shot.date = moment(shot.date).format("YYYY-MM-DD")
        }
        var dateId = shot.date + "-" + (shot.match? "M":"P")
        if (!dates[dateId]) {
          dates[dateId] = {
            date: dateId,
            shotCount: 0,
            shotResults: {}
          }
        }
        dates[dateId].shotCount++
        if (!dates[dateId].shotResults[shot.shotResult]) dates[dateId].shotResults[shot.shotResult] = 0
        dates[dateId].shotResults[shot.shotResult] ++

      })
      Object.keys(dates).forEach(date => {
        var obj = dates[date]
        var missHits = (obj.shotResults.Slice || 0) + (obj.shotResults.Hook || 0) + (obj.shotResults.Boffed || 0)
        var item =  {
          date: date,
          count: obj.shotCount,
          missHit: (missHits / obj.shotCount * 100).toFixed(1) + "%"
        }

        Object.keys(obj.shotResults).forEach(shotResult => item[shotResult] = (obj.shotResults[shotResult] / obj.shotCount * 100).toFixed(1) + "%")

        this.items.push(item)


      })

      this.items.sort((a,b) => a.date < b.date? 1: -1)
    }
  },
  async mounted () {

    this.shots = await ShotClient.getShots()
    this.createItems()
    this.loadHandicap()
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
}
</style>
