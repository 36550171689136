<template>
  <b-modal hide-footer hide-header centered id="putt-entry-modal" ref="putt-entry-modal">
    <b-container v-if="activeData">
      <b-row align-h="end" class="mb-3">
        <b-button  class="close" @click="close">×</b-button>
      </b-row>
      <HoleMap class="mb-3" v-if="hole && activeData.field.variant === 'hole' && (getGreenCoordinates() || getCurrentLocationCoordinates())" :greenCoordinates="getGreenCoordinates()" :currentLocation="getCurrentLocationCoordinates()"/>
      <b-row v-if="getGreenCoordinates()" class="mt-3" align-h="center">
        <b-col cols="auto" v-if="!currentLocation || distanceToPin">
          <div style="text-align:center">
            <b-spinner v-if="!currentLocation" type="grow" label="Spinning"></b-spinner>
          </div>
          <div>
            <h2 class="no-margin"><b-badge class="distance-badge" variant="light">{{distanceToPin}} </b-badge></h2>
          </div>
          <div class="small-grey-text">
            yards to center of green
          </div>
        </b-col>
      </b-row>
      <div v-if="activeData.field.variant === 'score'">
      <b-row class="margin-row" no-gutters>
        <b-col>
          <div class="d-block text-center">
            <h4>Shots</h4>
            <b-button-group  size="lg">
              <div  v-for="(shotCount, id) in [1,2,3,4,5]" v-bind:key="id" >
                <b-button squared class="shot-count-select-button" :variant="shotCount === activeShotCount? 'active' : ''" @click="activeShotCount = shotCount; saveScore(activeData, shotCount); counter++"  >{{shotCount}}</b-button>
              </div>
            </b-button-group>
            <b-button-group  size="lg">
              <div  v-for="(shotCount, id) in [6,7,8,9,10]" v-bind:key="id" >
                <b-button squared class="shot-count-select-button" :variant="shotCount === activeShotCount? 'active' : ''" @click="activeShotCount = shotCount; saveScore(activeData, shotCount); counter++"  >{{shotCount}}</b-button>
              </div>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="margin-row">
        <b-col>
          <div class="d-block text-center">
            <h4>Putts</h4>
            <b-button-group  size="lg">
              <b-button  squared v-for="(puttCount, id) in puttOptions" v-bind:key="id" :variant="activePuttCount === puttCount? 'active': ''" @click="activePuttCount = puttCount; addPutts(puttCount)">{{puttCount}}</b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="false" align-h="center">
        <b-button @click="addShots" variant="light" size="sm" >Add Shot Detail</b-button>
      </b-row>
      <b-row align-h="center">
        <p class="small-text">{{locationUpdates}} </p>
      </b-row>
    </div>
    <div v-if="activeData.field.variant === 'par'">
        <b-row align-h="center" class="mt-4">
          <h4 >HOLE {{activeData.item.hole}}</h4>
        </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form inline>
            <label class="mr-sm-2 form-label" for="par-dropdown">PAR</label>
            <b-form-select
              id="par-dropdown"
              class="hole-select"
              :options="[3,4,5]"
              v-model="match.course.scoreCard.filter(hole => hole.hole === activeData.item.hole)[0].par"
              @change="matchUpdated"
            ></b-form-select>
        </b-form>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form inline>
            <label class="mr-sm-2 form-label" for="si-dropdown">SI</label>
            <b-form-select
              id="si-dropdown"
              class="hole-select"
              :options="match.course.scoreCard.map(hole => hole.hole)"
              v-model="match.course.scoreCard.filter(hole => hole.hole === activeData.item.hole)[0].si"
              @change="matchUpdated"
            ></b-form-select>
          </b-form>

        </b-row>

    </div>
    </b-container>
  </b-modal>
</template>

<script>
import Match from '../models/Match'
import HoleMap from './HoleMap'
import { getDistance } from 'geolib';
export default {
  name: 'HoleModal',
  components: {
    HoleMap
  },
  props: ['activeData','match'],
  data() {
    return {
      puttOptions: [0,1,2,3,4,5],
      counter: 0,
      activeShotCount: 0,
      activePuttCount: 0,
      currentLocation: undefined,
      distanceToPin: undefined,
      locationUpdates: 0,
      hole: undefined,
      selectedSI: undefined
    }
  },
  mounted () {
    if (this.activeData) {


      this.activeShotCount = this.activeData.value
      this.activePuttCount = this.getPutts()
      this.hole = this.match.course.scoreCard[this.activeData.index]
    }
    this.getLocation()
    this.watchLocation()

  },
  watch: {
    activeData() {
        if (this.activeData.field.variant === "score") {
        this.activeShotCount = this.activeData.value
        this.activePuttCount = this.getPutts()
      }
      this.selectedSI = this.activeData.item.si
      this.hole = this.match.course.scoreCard[this.activeData.index]
      this.getDistanceToPin()
    },
    currentLocation () {
      this.getDistanceToPin()
    }
  },
  methods: {
    close () {
      this.$bvModal.hide('putt-entry-modal')
    },
    getGreenCoordinates() {
      if (this.hole && this.hole.locations && this.hole.locations.green) {
        return {latitude: this.hole.locations.green.lat, longitude: this.hole.locations.green.long}
      }
    },
    getCurrentLocationCoordinates() {
      if (this.currentLocation && this.currentLocation.coords){
        return {latitude: this.currentLocation.coords.latitude, longitude: this.currentLocation.coords.longitude}
      }
    },
    getShotButtonClass(shotCount) {
      return this.activeShotCount === shotCount ? 'active-shot-count-button' : ""
    },
    addShots() {
      this.$emit("navigateToShots")
    },
    getPutts () {
      if (this.activeData) {
        var player = this.match.players.filter(player => player.key === this.activeData.field.key)[0]
        if (!player || !player.scores) return
        var holeScore = player.scores.filter(hole => hole.hole === this.activeData.index + 1)[0]
        if (holeScore) return holeScore.putts
      }
    },
    async saveScore(data, score) {
      var player = this.match.players.filter(player => player.key === data.field.key)[0]
      if (!player.scores) player.scores = []
      var playerHole = player.scores.filter(hole => hole.hole === data.index + 1)[0]
      var putts = 2
      if (playerHole && playerHole.putts) putts = player.scores[data.index].putts
      var holeScore =  {hole: data.index + 1, shots: this.activeShotCount, putts: putts}
      if (!playerHole) {
        player.scores.push(holeScore)
      }else {
          playerHole === holeScore
      }
      //var scoreObj = this.match.players.filter(p => p.key === player.key)[0].scores.filter(hole => hole.hole === holeScore.hole)[0]
      var scoreObj = player.scores.filter(hole => hole.hole === holeScore.hole)[0]
      scoreObj.shots = holeScore.shots
      scoreObj.putts = holeScore.putts
      this.editing = undefined
      this.activeData = data
      this.match.save()
      this.$emit('matchUpdate', this.match)
    },
    addPutts(putts) {
      var player = this.match.players.filter(player => player.key === this.activeData.field.key)[0]
      var thisHole = player.scores.filter(hole => hole.hole === this.activeData.index + 1)[0]
      if (!thisHole) {
        player.scores.push({hole: this.activeData.index + 1, putts: putts})
      }else{
        thisHole.putts = putts
      }

      this.match.save()
      this.$emit('matchUpdate', this.match)
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(location => {
        this.currentLocation = location
      }, error => console.log(error), {enableHighAccuracy:true});
    },
    async matchUpdated() {
      await this.match.save()
      this.$emit('matchUpdate', this.match)
    },
    watchLocation() {
      navigator.geolocation.watchPosition(location => {
        this.currentLocation = location
        this.locationUpdates++
      }, error => console.log(error), {enableHighAccuracy:true});
    },
    getDistanceToPin() {
      if (this.currentLocation && this.hole && this.hole.locations && this.hole.locations.green) {
        this.distanceToPin = (getDistance(
          {
            latitude: this.currentLocation.coords.latitude,
            longitude: this.currentLocation.coords.longitude
          },
          {
            latitude: this.hole.locations.green.lat,
            longitude: this.hole.locations.green.long
          }
        ) * 1.09361).toFixed(0)
      }
    }
  }
}
</script>

<style>

.shot-count-select-button{
  height: 50px;
  width: 50px;
}

.margin-row {
  margin-bottom: 20px
}

.btn-active {
  border-color: #4a4a4a;
}

.btn-secondary {
  background-color:#4a4a4a;
  border-color: #4a4a4a;
}

.btn:focus {
  box-shadow: none
}

.small-text {
  font-size: 10px;
  color:  #ebebeb
}

.small-grey-text {
  font-size: 12px;
  text-align: center;
  color:  #5e5e5e
}

.no-margin {
  margin:0;
  text-align: center
}

.distance-badge {
  text-align: center;
  font-family: "Lucida Console", Courier, monospace;
  font-weight: normal;
}


</style>
