<template>
  <div>
    <VueApexChart v-if="series" height="200" :options="options" :series="series"></VueApexChart>
  </div>
</template>
<script>

import VueApexChart from 'vue-apexcharts'
import ShotStats from '../../models/ShotStats'
import moment from 'moment'
export default {
  name: 'ShotsToParTimeSeriesChart',
  components: {
    VueApexChart
  },
  props: ['matches', 'player'],
  data() {
    return {
      data: undefined,
      series: undefined,
      options: undefined
    }
  },
  mounted () {
    this.data = this.createData()
  },
  methods: {
    createData() {
      var shotStats = new ShotStats(this.matches, this.player)
      var shotsToPar = shotStats.shotsToPar
      var labels = shotsToPar.labels
      this.series = []
      labels.forEach(label => this.series.push({
        name: label,
        data: shotsToPar.series.map(obj=> obj[label].percent)
      }))

      this.options = {

        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 9,
            left:4,
            blur: 10,
            opacity: 0.2
          },

          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        },
        stroke: {
          curve: 'smooth',
          width: labels.map(() => '2')
        },
        colors: ['#ff3b3b', '#ff9a3b', '#ffe83b','#3bff4f', '#3ba0ff','#f53bff'],
        xaxis: {
          labels: {
            rotate: 0,
            style: {
              fontSize: '0px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (val => (val*100).toFixed(0) + "%")
          }
          //min: 0,max: 1
        }
      }
    }

  }
}

function getDateList(data) {
  return data.map(obj => {
    return moment(obj.date).format("YYYY-MM-DD")
  })
}

</script>
