<template>
  <div>
    <VueApexChart  v-if="series" height="200" :options="options" :series="series"></VueApexChart>
  </div>
</template>
<script>

import VueApexChart from 'vue-apexcharts'
import ShotDataByDate from '../../models/ShotDataByDate'
import moment from 'moment'
export default {
  name: 'HandicapTimeSeriesChart',
  components: {
    VueApexChart
  },
  props: ['data'],
  data() {
    return {
      series: undefined,
      options: undefined
    }
  },
  mounted () {
    this.createData()
  },
  methods: {
    createData() {
      this.series = [{
        name: "HCP",
        data: this.data.map(obj => obj.handicap)
      }]

      this.options = {
        dataLabels:{
          enabled: false
        },
        chart: {
          type: 'area',

          dropShadow: {
            enabled: true,
            color: '#000',
            top: 9,
            left:4,
            blur: 10,
            opacity: 0.2
          },

          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: ['4']
        },
        colors: ['#f5e342'],
        xaxis: {
          categories: getDateList(this.data),
          labels: {
            rotate: 0,
            style: {
              fontSize: '10px'
            }
          }
        },
        yaxis: {
          min: 0,
          max: 36,
          labels: {
            formatter: function(val, index) {
              return val.toFixed(1);
            }
          }
        }
      }

    }
  }
}

function getDateList(data) {
  return data.map(obj => {
    return moment(obj.date).format("YYYY-MM-DD")
  })
}

</script>
