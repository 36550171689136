export default class ShotDataByDate extends Object {
    constructor(shots) {
      super()
      var shotStats = ['direction','shotResult','distance']
      shots.forEach(shot => {
        if (!this[shot.date]) this[shot.date] = {totals : {count: 0}}
        if (!this[shot.date].clubs) this[shot.date].clubs = {}
        if (!this[shot.date].clubs[shot.club]) this[shot.date].clubs[shot.club] = {count: 0}
        this[shot.date].totals.count ++
        this[shot.date].clubs[shot.club].count++
        shotStats.forEach(shotStat => {
          if (!this[shot.date].clubs[shot.club][shotStat]) this[shot.date].clubs[shot.club][shotStat] = {}
          if (!this[shot.date].totals[shotStat]) this[shot.date].totals[shotStat] = {}
          if (!this[shot.date].clubs[shot.club][shotStat][shot[shotStat]]) this[shot.date].clubs[shot.club][shotStat][shot[shotStat]] = {count: 0}
          if (!this[shot.date].totals[shotStat][shot[shotStat]]) this[shot.date].totals[shotStat][shot[shotStat]] = {count: 0}
          this[shot.date].clubs[shot.club][shotStat][shot[shotStat]].count ++
          this[shot.date].totals[shotStat][shot[shotStat]].count ++
        })

      })
    }
}
