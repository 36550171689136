<template>
  <div >
    <b-table small responsive sticky-header :fields="fields" :items="items" >
      <template v-slot:cell(action)="data">
        <b-button @click="deleteShot(data.item)">Delete</b-button>
      </template>
      <template v-slot:cell(club)="data" >
        <b-button variant="outline" v-if="!isEditing(data)" @click="setToEdit(data)">{{data.value}}</b-button>
        <b-form inline v-if="isEditing(data)">
          <b-form-select v-model="data.value" :options="options" v-on:change="saveShot(data)"></b-form-select>
        </b-form>
      </template>
      <template v-slot:cell(match)="data" >
        <b-form>
          <b-form-checkbox v-model="data.value" switch v-on:change="saveShot(data)"/>
        </b-form>
      </template>
    </b-table>
  </div>
</template>

<script>
import * as StandardData from '../models/StandardData'
import * as ShotClient from '../clients/ShotClient'
export default {
  name: 'ShotList',
  components: {

  },
  props: ['shots'],
  data () {
    return {
      items: undefined,
      fields: ['match', 'club', {key:'shotResult', label:"Result"},'direction','distance','action'],
      options: StandardData.clubs,
      editing: undefined
    }
  },
  methods: {
    isEditing(data) {
      return this.editing && data.field.key === this.editing.field && data.index === this.editing.index
    },
    setToEdit(data) {
      this.editing = {
        field: data.field.key,
        index: data.index,
        value: data.value
      }
    },
    createShotItems() {
      this.shotItems = {fields: [
        {key: "club", label: "Club"}
      ]}
      this.items = this.shots
    },
    async saveShot(data) {
      this.shots[data.index].match = !data.value
      await ShotClient.saveShot(this.shots[data.index])
    },
    deleteShot(data) {
      this.$emit("deleteShot", data)
    }
  },
  mounted () {
    this.createShotItems()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  margin-bottom: 10px
}
</style>
