<template>
  <b-modal hide-header hide-footer centered id="player-edit-modal" >
    <b-form>
      <label class="form-label" for="handicap-entry">FIRST NAME</label>
      <b-form-input id="first_name_entry" class="mb-2" type="text" v-model="player.firstName"></b-form-input>
      <label class="form-label" for="handicap-entry">SURNAME</label>
      <b-form-input id="surname_entry" class="mb-2" type="text" v-model="player.surname"></b-form-input>
      <label class="form-label" for="handicap-entry">HANDICAP</label>
      <b-form-input id="handicap-entry" class="mb-2 handicap-entry" type="number" v-model="player.handicap">Handicap</b-form-input>
      <label class="form-label" >Match Handicap: {{player.matchHandicap}}</label>
      <b-button class="mt-2 button-primary" v-if="!player.key" @click="addPlayer">ADD</b-button>
      <b-button class="mt-2 button-primary" v-if="player.key" @click="updatePlayer">Update</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import Match from '../models/Match'
import { getDistance } from 'geolib';
export default {
  name: 'PlayerEditModal',
  components: {
  },
  props: ['player'],
  data() {
    return {

    }
  },
  mounted () {
    if (this.player) console.log(this.player.matchHandicap)
  },
  watch: {

  },
  methods: {
    updatePlayer() {
      this.$emit("playerUpdated", this.player)
    },
    addPlayer() {
      this.$emit("playerAdded", this.player)
    }
  }
}
</script>

<style>

.shot-count-select-button{
  height: 50px;
  width: 50px;
}

.margin-row {
  margin-bottom: 20px
}

.btn-active {
  border-color: #4a4a4a;
}

.btn-secondary {
  background-color:#4a4a4a;
  border-color: #4a4a4a;
}

.btn:focus {
  box-shadow: none
}

.small-text {
  font-size: 10px;
  color:  #ebebeb
}

.small-grey-text {
  font-size: 12px;
  text-align: center;
  color:  #5e5e5e
}

.no-margin {
  margin:0;
  text-align: center
}

.distance-badge {
  text-align: center;
  font-family: "Lucida Console", Courier, monospace;
  font-weight: normal;
}

.handicap-entry {
  max-width: 25%;
}
</style>
