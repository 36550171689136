<template>
  <div>
    <b-button-group class="mt-2" v-if="!course">
      <b-button @click="newCourse(9)">9</b-button>
      <b-button @click="newCourse(18)">18</b-button>
    </b-button-group>
    <div v-if="course">
      <b-form inline>
        <b-form-input class="mt-2 mr-2" v-model="course.location" placeholder="location" />
        <b-form-input class="mt-2" v-model="course.courseName" placeholder="courseName" />
      </b-form>
      <b-form inline v-for="hole in course.scoreCard" v-bind:key="hole.hole">
        <h6 class="mr-2"> {{hole.hole}}</h6>
        <b-form-group label="par">
          <b-form-select class="mr-2" v-model="course.scoreCard[hole.hole - 1].par" :options="[3,4,5]"></b-form-select>
        </b-form-group>
        <b-form-group label="si">
          <b-form-select class="mr-2" v-model="course.scoreCard[hole.hole - 1].si" :options="siOptions()"></b-form-select>
        </b-form-group>
        <b-form-group label="lat long">
          <b-form-input class="mr-2" v-model="course.scoreCard[hole.hole - 1].latLong" placeholder="Coordinates"></b-form-input>
        </b-form-group>
      </b-form>
      <b-button @click="submit"><p v-if="!loading">Submit</p>
        <b-spinner v-if="loading" variant="success" type="grow" label="Spinning"></b-spinner>
      </b-button>

    </div>
  </div>
</template>

<script>
import Course from '../models/Course'

export default {
  components: {
  },
  props: [],
  data() {
    return {
      course: undefined,
      loading: false
    }
  },
  mounted () {
  },
  methods: {
    newCourse(holes) {
      this.course = new Course(holes)
    },
    siOptions() {
      var arr = []
      for (var i = 1; i <= this.course.scoreCard.length; i++) {
        arr.push(i)
      }
      return arr
    },
    async submit() {
      this.loading = true
      await this.course.save()
      this.loading = false
    }
  }
}

</script>
