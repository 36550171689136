<template>
  <div >
    <b-breadcrumb v-if="mode !== 'club'" >
      <b-breadcrumb-item v-if="club">
        <a @click="backTo('club')">{{club}}</a>
      </b-breadcrumb-item>
      <b-breadcrumb-item v-if="shotResult">
        <a @click="backTo('shotResult')"  >{{shotResult}}</a>
      </b-breadcrumb-item>
      <b-breadcrumb-item v-if="direction">
        <a @click="backTo('direction')"  >{{direction}}</a>
      </b-breadcrumb-item>
      <b-breadcrumb-item v-if="distance">
        <a>{{distance}}</a>
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-card class="mt-2">
      <b-form-checkbox v-model="match" class="mb-2" switch> Match </b-form-checkbox>
      <ClubSelector v-if="mode === 'club'" v-on:clubSelected="clubSelected" />
      <ShotResultSelector v-if="mode === 'shotResult'" v-on:shotResultSelected="shotResultSelected"/>
      <DirectionSelector v-if="mode === 'direction'" v-on:directionSelected="directionSelected"/>
      <DistanceSelector v-if="mode === 'distance'" v-on:distanceSelected="distanceSelected"/>
    </b-card>
    <b-card class="mt-2">
      <ShotList class="half-page" v-if="shots" :shots="shots" v-on:deleteShot="deleteShot" v-on:shotUpdated="loadShots"/>
    </b-card>
  </div>
</template>

<script>
import ClubSelector from './ClubSelector'
import ShotResultSelector from './ShotResultSelector'
import DistanceSelector from './DistanceSelector'
import DirectionSelector from './DirectionSelector'
import ShotList from './ShotList'
import * as ShotClient from '../clients/ShotClient'
export default {
  name: 'ShotPage',
  components: {
    ClubSelector, ShotResultSelector, DistanceSelector,ShotList,DirectionSelector
  },
  props: {
    msg: String,
    matchContext: Boolean
  },
  data () {
    return {
      mode: "club",
      club: undefined,
      shotResult: undefined,
      distance: undefined,
      shots: undefined,
      direction: undefined,
      match: false
    }
  },
  methods: {
    clubSelected(club) {
      this.club = club
      this.mode = "shotResult"
    },
    shotResultSelected(shotResult) {
      this.shotResult = shotResult
      if (shotResult === "Straight" || shotResult === "Fade" || shotResult === "Draw" || shotResult === "Under" || shotResult === "Over") {
          this.mode = "direction"
      }else {
          this.distance = "NA"
          this.direction = "NA"
          this.distance = "NA"
          this.saveShot()
      }
    },
    directionSelected(direction) {
      this.direction = direction
      this.mode = "distance"
    },
    async distanceSelected(distance) {
      this.distance = distance
      await this.saveShot()
    },
    async saveShot() {
      await ShotClient.saveShot ({
        club: this.club,
        shotResult: this.shotResult,
        distance: this.distance,
        direction: this.direction,
        match:this.match,
        date: new Date()
      })
      await this.loadShots()
      this.shotResult = undefined
      this.distance = undefined
      this.direction = undefined
      if (this.match) {
        this.mode = "club"
      }else {
        this.mode = "shotResult"
      }
    },
    backTo(step) {
      switch (step) {

        case 'club':
          this.club = undefined
          this.shotResult = undefined
          this.distance = undefined
          this.direction = undefined
          break;
        case 'shotResult' : {
          this.shotResult = undefined
          this.distance = undefined
          this.direction = undefined
          break;
        }
        case 'direction' : {
          this.distance = undefined
          this.direction = undefined
          break;
        }
      }

      this.mode = step
    },
    async loadShots() {
      this.shots = undefined
      this.shots = await ShotClient.getShots({limit: 50})
    },
    async deleteShot(shot) {
      await ShotClient.deleteShot(shot)
      await this.loadShots()
    }
  },
  mounted () {
    this.loadShots()
    if (this.matchContext) this.match = this.matchContext
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  margin-bottom: 10px
}

.half-page {
  max-height: 50%
}
</style>
