<template>
  <div >
    <b-container>
      <b-row no-gutters align-h="center">
        <b-col v-for="(club, id) in clubs" v-bind:key="id" cols="3" >
          <b-button class="selector-button"  squared size="lg" @click="selected(club)">{{club}}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {clubs} from '../models/StandardData'
export default {
  name: 'ClubSelector',
  props: {
    msg: String
  },
  data () {
    return {
      clubs: clubs,
      clubGroups: [["D","5W","3H","4I"],["5I","6I","7I","8I"],["9I","PW","SW"]]
    }
  },
  methods: {
    selected(club) {
      this.$emit("clubSelected", club)
    }
    /*
    createClubGroups (cols) {
      this.clubGroups = []
      for (var i = 0; i < cols.length; i++) {
        var group = []
        group.push(clubs[i])
      }
    }
    */
  },
  mounted () {
    //createClubGroups()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
