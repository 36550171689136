<template>
  <div>
    <VueApexChart  v-if="series" height="215" :options="options" :series="series" ></VueApexChart>
  </div>
</template>
<script>
import VueApexChart from 'vue-apexcharts'
import ShotStats from '../../models/ShotStats'
import moment from 'moment'
export default {
  name: 'ShotsToParDonutChart',
  components: {
    VueApexChart
  },
  props: ['matches', 'player'],
  data() {
    return {
      data: undefined,
      series: undefined,
      options: undefined
    }
  },
  mounted () {
    this.data = this.createData()
  },
  methods: {
    createData() {
      var shotStats = new ShotStats(this.matches, this.player)
      var shotsToPar = shotStats.shotsToPar
      var labels = shotsToPar.labels
      var latestShotsToPar = shotsToPar.series[shotsToPar.series.length - 1]
      this.series = labels.map(key => latestShotsToPar[key].percent)
      this.options = {
        chart: {
          type: 'pie'
        },
        legend: {
          show: false
        },
        labels:labels,
        plotOptions: {
          radialBar: {
            hollow: {
              size: '50%'
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: true,
                fontSize: '22px'
              }
            }
          },

        },
      }
    }
  }
}

function getDateList(data) {
  return data.map(obj => {
    return moment(obj.date).format("YYYY-MM-DD")
  })
}

</script>
