<template>
  <div>
    <b-card class="small-padding mb-2  " >
      <b-table :items="items" :fields="fields" class="mt-2 match-score-table" small v-on:row-clicked="rowClicked" v-on:head-clicked="headClicked"> </b-table>
    </b-card>
  </div>
</template>
<script>
import Match from '../models/Match'
import Golfer from '../models/Golfer'
import * as Scorer from '../models/Scorer'
export default {
  name: 'MatchScoringPage',
  components: {
  },
  props: ['match','reloadCounter'],
  data() {
    return {
      items: [],
      fields: []
    }
  },
  mounted () {
    this.createItems()
  },
  watch : {
    reloadCounter: function() {
      this.createItems()
    }
  },
  methods: {
    rowClicked(item, index, event) {
      this.$emit('playerClicked', this.match.players[index])
    },
    headClicked(key, field, event) {
      this.$emit("scoreTypeChanged", key)
    },
    createItems() {
      this.items = []
      this.fields = [
        {key: 'firstName', label: '', tdClass: 'player-cell'},
        {key: 'stableford', label: 'Stableford'},
        {key: 'matchPlay', label: 'Match'},
        {key: 'strokePlay', label: 'Stroke'}
      ]

      if (this.match.players.length > 2 && !this.match.teams) {
          this.fields.splice(2,1)
      }

      this.match.players.forEach(player => {
        var scoreObj = {
          firstName: player.firstName,
          stableford: this.totalStableFordScore(player.handicap,player.scores, this.match.course.scoreCard)
        }
        if (this.match.players.length > 1) {
          var matchPlayScore
          if (this.match.players.length === 2) {
            matchPlayScore = Scorer.matchPlayScore(player, this.match.players.filter(p => p !== player)[0], this.match.course.scoreCard)
          }else {
            if (this.match.teams) {
              matchPlayScore = Scorer.teamMatchPlayScore(player.key, this.match)
            }
          }
          scoreObj.matchPlay = matchPlayScore > 0 ? matchPlayScore + "Up" : matchPlayScore === 0? "A/S" : (matchPlayScore * -1) + "Dn"
        }
        scoreObj.strokePlay = this.strokePlayScore(player, this.match.course.scoreCard)
        this.items.push (scoreObj)
      })
    },
    strokePlayScore(player, scoreCard) {
      var total = 0
      if (player.scores) {
        player.scores.forEach(hole => {
          if (hole.shots)  {
            var scoreCardHole = getScoreCardHole(scoreCard, hole.hole)
            var grossShots = strokesWithHandicap(player.handicap, hole.shots,scoreCardHole.si, scoreCard.length)
            total += grossShots - (1 * scoreCardHole.par)
          }
        })
      }
      return total > 0? "+" + total : total === 0 ? "E" : total
    },
    totalStableFordScore(handicap, scores, scoreCard) {
      var total = 0
      if (scores && scoreCard) {
        scores.forEach(hole => {
          if (hole.shots) {
            var scoreCardHole = scoreCard.filter(courseHole => 1 * courseHole.hole === 1* hole.hole)[0]
            total+= Scorer.stableFordScore(scoreCardHole.par, hole.shots, handicap, scoreCardHole.si, scoreCard.length)
          }
        })
      }
      return total
    },

  }
}

function getScoreCardHole(scoreCard, holeNumber) {
    return scoreCard.filter(courseHole => 1 * courseHole.hole === 1* holeNumber)[0]
}

function shotsForHole(player, holeNumber) {
  if (player.scores) {
    var holeForPlayer = player.scores.filter(hole => 1 * hole.hole === 1 * holeNumber)[0]
    if (holeForPlayer) return holeForPlayer.shots
  }
  return 0
}

function getShots(handicap, strokeIndex, holes) {
  return Math.floor(((handicap - strokeIndex) / holes) + 1)
}

function strokesWithHandicap(handicap, strokes, strokeIndex, holes) {
  if (1 * strokes === 0) return 0
  var shots = getShots(handicap, strokeIndex, holes)
  /*
  console.log("Handicap: " + handicap)
  console.log("Strokes: " + strokes)
  console.log("SI: " + strokeIndex)
  console.log("Shots: " + shots)
  console.log("Gross Score: " + (strokes - shots))
  */
  return strokes - shots
}
</script>

<style>
.match-score-table {
}

.match-score-table th {
  font-size: 12px
}

.match-score-table td {
}

.player-cell {
  background-color: #e8e8e8;
  font-weight: bold;
}

.small-padding .card-body {
  padding-bottom:5px;
  padding-top:5px;
}

</style>
