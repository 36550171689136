import GreensInRegulation from './GreensInRegulation'
import PuttsStats from './PuttsStats'
import ShotsToPar from './ShotsToPar'
export default class ShotStats {
  constructor(matches, player) {
    this.playerScores = getPlayerScores(matches, player)
    this.greensInRegulation = new GreensInRegulation(this.playerScores)
    this.putts = new PuttsStats(this.playerScores)
    this.shotsToPar = new ShotsToPar(this.playerScores)
  }
}


function getPlayerScores(matches, player) {
  var scoreList = []
  matches.forEach(match => {
    scoreList = scoreList.concat(match.players.filter(p => p.key === player.key)[0].scores.map(hole => {
      hole.par = match.course.scoreCard.filter(scoreCardHole => scoreCardHole.hole === hole.hole)[0].par
      hole.date = match.date
      return hole
    }))
  })

  //Sort by date (newest first) and hole number (to make it easy to get last 50 holes)
  scoreList = scoreList.sort((a,b) => a.hole < b.hole? 1:-1)
  scoreList = scoreList.sort((a,b) => new Date(a.date) < new Date(b.date)? 1 : new Date(a.date) > new Date(b.date)?-1 : 0)

  return scoreList
}
