<template>
  <div>
    <GmapMap
    v-if="showMap"
    :center="getCentrePoint()"
    :zoom="17"
    map-type-id="satellite"
    style="width: 100%; height: 500px"
    @click="locationClicked"
    :options="mapOptions"
  >
  <GmapInfoWindow  :opened="showInfoWindow" :position="infoWindowPosition" @closeclick="showInfoWindow=false">{{distanceToPoint}}</GmapInfoWindow>
  <GmapMarker icon="./baseline_my_location_white_18dp.png" :position="currentLocation? {lat: currentLocation.latitude, lng: currentLocation.longitude} : false" />
  <GmapMarker v-if="greenCoordinates" icon="./baseline_flag_white_18dp.png" :position="{lat: greenCoordinates.latitude, lng: greenCoordinates.longitude} " />
  </GmapMap>
  </div>
</template>

<script>
import { getDistance, getCenter } from 'geolib';
export default {
  name: 'HoleMap',
  components: {
  },
  props: ['greenCoordinates', 'currentLocation'],
  data() {
    return {
      showInfoWindow: false,
      infoWindowPosition: undefined,
      distanceToPoint: undefined,
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false
      },
      showMap: true
    }
  },
  mounted () {
  },
  watch: {
    currentLocation() {
      this.getDistanceToPoint()
    }
  },
  methods: {
    getCentrePoint() {
      if (this.currentLocation) {
        if (this.greenCoordinates) {
          var distance = getDistance(this.greenCoordinates, this.currentLocation)
          if (distance < 1000) {
              var center =  getCenter([
                this.greenCoordinates,
                this.currentLocation
            ]);
            return {lat: center.latitude, lng: center.longitude}
          }else {
            return {lat:  this.greenCoordinates.latitude, lng:  this.greenCoordinates.longitude}
          }
        }else {
          return {lat:  this.currentLocation.latitude, lng:  this.currentLocation.longitude}
        }
      }else {
        if (this.greenCoordinates) {
          return {lat:  this.greenCoordinates.latitude, lng:  this.greenCoordinates.longitude}
        }else {
          console.log("Not showing map")
          this.showMap = false
        }
      }
    },
    locationClicked(mapsMouseEvent) {
      this.infoWindowPosition = mapsMouseEvent.latLng
      this.showInfoWindow = true
      this.getDistanceToPoint()
    },
    getDistanceToPoint() {

      if (this.currentLocation && this.infoWindowPosition) {
        var clickedPointCoords = {
          latitude: this.infoWindowPosition.lat(),
          longitude: this.infoWindowPosition.lng()
        }
        var distance = (getDistance(
          {
            latitude: this.currentLocation.latitude,
            longitude: this.currentLocation.longitude
          },
          {
            latitude: this.infoWindowPosition.lat(),
            longitude: this.infoWindowPosition.lng()
          }
        ) * 1.09361).toFixed(0)

        this.distanceToPoint = distance + " yds"
      }else {
        this.distanceToPoint = "NOP"
      }

    }
  }
}
</script>

<style>

</style>
