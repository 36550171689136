export function matchPlayScore(playerA, playerB, scoreCard, holesCompleted) {
  var total = 0
  if (!holesCompleted) holesCompleted = scoreCard.length
  if (playerA.scores && playerB.scores) {
    var handicapDiff = playerA.handicap - playerB.handicap
    playerA.scores.forEach(hole => {
      var scoreCardHole = scoreCard.filter(h => 1 * h.hole === 1 * hole.hole)[0]
      if (scoreCardHole && scoreCardHole.hole <= holesCompleted) { //Allows me to filter out holes to score up to a given hole
        var playerAShots = shotsForHole(playerA, hole.hole)
        var playerBShots = shotsForHole(playerB, hole.hole)
        var shotsBeforeHandicap
        if (handicapDiff > 0) {
          shotsBeforeHandicap = playerAShots
          playerAShots += getShots(handicapDiff, scoreCardHole.si, scoreCard.length) * -1
        }else {
          shotsBeforeHandicap = playerBShots
          playerBShots += getShots(handicapDiff * -1, scoreCardHole.si, scoreCard.length) * -1
        }
        if (playerBShots) {
          total += playerAShots < playerBShots? 1: playerBShots < playerAShots? -1: 0
        }
      }
    })
  }
  return total
}

export function teamMatchPlayScore(playerKey, match, holesCompleted) {
  var total = 0
  //var player = match.players.filter(player => player.key === playerKey)[0]
  if (!holesCompleted) holesCompleted = match.course.scoreCard.length
  // Add the match handicaps
  var handicaps = [...match.players].sort((a,b) => a.handicap - b.handicap).map(obj => obj.handicap)
  var minHandicap = handicaps[0]
  match.players.map(player => {
    player.matchHandicap = player.handicap - minHandicap
    return player
  })

  var scoresForHoles = []
  match.course.scoreCard.forEach(hole => {
    if (hole.hole <= holesCompleted) {
      var playerScoresForHole = match.players.map(player => {
        var playerScore = {
          hole: hole.hole,
          key: player.key,
          team: match.teams.teamA.players.filter(key => key === player.key).length === 1? 'teamA': 'teamB',
          grossScore: player.scores.filter(score => score.hole === hole.hole)[0].shots,
          shots: getShots(player.matchHandicap, hole.si, match.course.scoreCard.length)
        }
        playerScore.netScore = playerScore.grossScore - playerScore.shots
        return playerScore
      })
      if (playerScoresForHole.filter(ps => !ps.grossScore).length === 0) {
        scoresForHoles.push({players: playerScoresForHole})
      }
    }
  })

  scoresForHoles = scoresForHoles.map(scoresForHole => {
    scoresForHole.teams = {
      teamA:{
        netScore: lowestScoreForTeamOnHole(scoresForHole.players, 'teamA'),
        points:0
      },
      teamB: {
        netScore: lowestScoreForTeamOnHole(scoresForHole.players, 'teamB'),
        points:0
      }
    }

    if (scoresForHole.teams.teamA.netScore < scoresForHole.teams.teamB.netScore) {
      scoresForHole.teams.teamA.points = 1
    }

    if (scoresForHole.teams.teamA.netScore > scoresForHole.teams.teamB.netScore) {
      scoresForHole.teams.teamB.points = 1
    }
    return scoresForHole
  })

  for (var i = 0; i < scoresForHoles.length; i++) {
    scoresForHoles[i].teams.teamA.cumulativePoints = scoresForHoles[i - 1]? scoresForHoles[i-1].teams.teamA.cumulativePoints + scoresForHoles[i].teams.teamA.points : scoresForHoles[i].teams.teamA.points
    scoresForHoles[i].teams.teamB.cumulativePoints = scoresForHoles[i - 1]? scoresForHoles[i-1].teams.teamB.cumulativePoints + scoresForHoles[i].teams.teamB.points : scoresForHoles[i].teams.teamB.points
    scoresForHoles[i].teams.teamA.diff = scoresForHoles[i].teams.teamA.cumulativePoints - scoresForHoles[i].teams.teamB.cumulativePoints
    scoresForHoles[i].teams.teamB.diff = scoresForHoles[i].teams.teamB.cumulativePoints - scoresForHoles[i].teams.teamA.cumulativePoints
  }

  //Logging
  /*
  scoresForHoles.forEach(hole => {
    var log = hole.players.map(player => {
      return (player.key + "-" + player.team + ": " + player.netScore + " (" + player.grossScore + ")")
    })
    console.log(log.join("\n"))
    console.log(hole.teams)
  })
  */
  var thisPlayerTeam = match.teams.teamA.players.filter(key => key === playerKey).length === 1? 'teamA': 'teamB'
  var thisPlayerScore = scoresForHoles[scoresForHoles.length -1].teams[thisPlayerTeam].diff
  return thisPlayerScore
}

function lowestScoreForTeamOnHole(playerScores, team) {
  return playerScores.filter(player => player.team === team).sort((a,b) => a.netScore - b.netScore)[0].netScore
}

export function skinsScore(players, playerKey, scoreCard, holesCompleted) {
  var total = 0
  if (!holesCompleted) holesCompleted = scoreCard.length
  //Set skins handicap
  var sortedPlayers = players.concat().sort((a,b) => a.handicap - b.handicap)
  var lowestHandicap = players[0].handicap
  players.forEach(player => {
    //player.matchHandicap = player.handicap - lowestHandicap
  })
  scoreCard.sort((a,b)  => a.hole - b.hole)
  /*
  playerA.scores.forEach(hole => {
    var scoreCardHole = scoreCard.filter(h => 1 * h.hole === 1 * hole.hole)[0]
    if (scoreCardHole && scoreCardHole.hole <= holesCompleted) { //Allows me to filter out holes to score up to a given hole
      var playerAShots = shotsForHole(playerA, hole.hole)
      var playerBShots = shotsForHole(playerB, hole.hole)
      var shotsBeforeHandicap
      if (handicapDiff > 0) {
        shotsBeforeHandicap = playerAShots
        playerAShots += getShots(handicapDiff, scoreCardHole.si, scoreCard.length) * -1
      }else {
        shotsBeforeHandicap = playerBShots
        playerBShots += getShots(handicapDiff * -1, scoreCardHole.si, scoreCard.length) * -1
      }

      if (playerBShots) {
        total += playerAShots < playerBShots? 1: playerBShots < playerAShots? -1: 0
      }
    }
  })
  */
  return total
}

export function skinsScoreCard () {

}


export function stableFordScore(par, score, handicap, strokeIndex, holes) {
  if (1 * score === 0) return 0
  var grossScore = 0
  if (!handicap) handicap = 0
  var netScore = (1 * par) - (1 * score)
  var shots = getShots(handicap, strokeIndex, holes)
  grossScore = netScore + shots + 2
  return Math.max(0,grossScore)
}

function shotsForHole(player, holeNumber) {
  if (player.scores) {
    var holeForPlayer = player.scores.filter(hole => 1 * hole.hole === 1 * holeNumber)[0]
    if (holeForPlayer) return holeForPlayer.shots
  }
  return 0
}

function getShots(handicap, strokeIndex, holes) {
  var shots = Math.floor(((handicap - strokeIndex) / holes) + 1)
  return shots
}
