<template>
  <div>
    <GoogleLogin v-if="!signedIn" :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure" >Login</GoogleLogin>
    <b-button v-if="signedIn" @click="signOut" variant="outline" class="small-button-text">Sign Out</b-button>
  </div>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import Vue from 'vue'
import { LoaderPlugin } from 'vue-google-login';
var clientId = "726629554189-9q49g6o2lqd2mtvgldmprqofnune34o6.apps.googleusercontent.com"
Vue.use(LoaderPlugin, {
  client_id: clientId
});
export default {
  name: 'GoogleSignIn',
  components: {
    GoogleLogin
  },
  data() {
    return {
      signedIn: false,
      params: {
        client_id:clientId
      },
      renderParams: {
      },
      auth2 : undefined
    }
  },
  methods: {
    signOut() {
        this.auth2.signOut()
        console.log(this.auth2.isSignedIn.get())
        this.signedIn = false
        this.$emit("signOut")
    },
    onSuccess(data) {
        this.signedIn = true
        this.$emit("signIn")
    },
    onFailure(data) {
      console.log(data)
    },
    async checkSignInStatus() {
      Vue.GoogleAuth.then(auth2 => {
        this.auth2 = auth2
        this.signedIn = auth2.isSignedIn.get()
        this.signedIn? this.$emit("signIn") : this.$emit("signOut")
      })

    }
  },
  async mounted() {
    await this.checkSignInStatus()
  }
}
function onSignIn(user) {
  console.log(user)
}
</script>

<style>
.small-button-text {
  font-size: 12px;
  text-decoration: underline;
}
</style>
