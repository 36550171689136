<template>
  <div>
    <VueApexChart  v-if="series" height="200" :options="options" :series="series"></VueApexChart>
  </div>
</template>
<script>

import VueApexChart from 'vue-apexcharts'
import {directions as standardDirections} from '../models/StandardData'
export default {
  name: 'DirectionChart',
  components: {
    VueApexChart
  },
  props: ['data', 'club'],
  data() {
    return {
      options: undefined,
      series: undefined
    }
  },
  mounted () {
    this.createData()
  },
  watch: {
    club() {
      this.createData()
    }
  },
  methods: {
    createData() {
      var directions = {}
      var count = 0
      this.data.forEach(shot => {
        if (!directions[shot.direction]) directions[shot.direction] = 0
        if (!this.club || shot.club === this.club)  {
          directions[shot.direction] ++
          count++
        }
      })
      var data = {
        labels: ["VL","L","Straight","R","VR"],
        datasets: []
      }
      var directionPercents = []
      data.labels.forEach(direction => {
        directionPercents.push((directions[direction] / count * 100).toFixed(1))
      })

      console.log(directionPercents)
      this.series = [
        {
          name: "Direction",
          data: directionPercents
        }
      ]

      this.options = {
        title: {
          text: "Directions",
          align: "center"
        },
        chart: {
          type: 'bar',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 9,
            left:4,
            blur: 10,
            opacity: 0.2
          },

          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: ['4']
        },
        colors: ['#03e3fc'],
        xaxis: {
          categories: standardDirections,
          labels: {
            rotate: -30,
            style: {
              fontSize: '10px'
            }
          }
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: (val => val.toFixed(0))
          }
        }
      }

    }
  }
}

</script>
