import * as MatchesClient from '../clients/MatchesClient'
import Match from './Match'
export default class MatchList extends Array {
    constructor() {
      super()
      this.load = load
    }
}

async function load() {
  await reconcileLocalMatches()
  try {
    var response = await MatchesClient.getMatches()
    for (var obj of response) {
      var match = new Match()
      await match.load(obj.uuid)
      if (match.uuid && !match.synced) {
        this.push(match)
        match.save()
      }else {
        Object.keys(obj).forEach(key => {
          match[key] = obj[key]
          match.synced = true
        })
        this.push(match)
        match.save(true, true)
      }
    }
  }catch (err) {
    console.log("Couldn't load matches from remote")
  }
}

async function reconcileLocalMatches() {
  for (var i in localStorage) {
    if (i.substr(0,6) === 'match-') {
      var data = JSON.parse(localStorage[i])
      if (!data.synced) {
        console.log("Reconciling match " + data.uuid)
        var match = new Match()
        Object.keys(data).forEach(key => match[key] = data[key])
        await match.save()
      }
    }
  }
}
