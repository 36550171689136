import axios from 'axios'
import {v4 as uuidv4} from 'uuid'
var baseUrl = "https://fceul9wqvk.execute-api.us-east-1.amazonaws.com/prod/golf-tracker-api"
import Vue from 'vue'
import { LoaderPlugin } from 'vue-google-login';

Vue.use(LoaderPlugin, {
  client_id: "726629554189-9q49g6o2lqd2mtvgldmprqofnune34o6.apps.googleusercontent.com"
});
export async function saveShot(shot) {
  if (!shot.uuid) shot.uuid = uuidv4()
  var token = await getSignedInUserToken()
  var result  = await axios.post(baseUrl, shot, {headers: {'user-token' : token}})
  return result
}

export async function getShots(options) {
  var token = await getSignedInUserToken()
  var url = baseUrl
  if (options && options.limit) url += "?limit=" + options.limit
  var result  = await axios.get(url, {headers: {'user-token' : token}})
  return result.data
}

export async function deleteShot(shot) {
  var token = await getSignedInUserToken()
  var result  = await axios.delete(baseUrl, {headers: {'user-token' : token},data:{id: shot.id}})
  return result
}

async function getSignedInUserToken() {
  var auth2 = await Vue.GoogleAuth
  var user = auth2.currentUser.get()
  return user.getAuthResponse().id_token
}

export async function migrateShots() {
  var shots = await getShots()
  for (const shot of shots) {
    await saveShot(shot)
  }
}
